<script>
import { Carousel, Slide } from "vue-carousel";
import {
  UserIcon,
  MailIcon,
  KeyIcon,
  ArrowUpIcon,
  HelpCircleIcon,
} from "vue-feather-icons";

import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";

/**
 * Index-1
 */
export default {
  components: {
    Navbar,
    Switcher,
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    KeyIcon,
    ArrowUpIcon,
    Footer,
    HelpCircleIcon,
  },
  methods: {},
};
</script>

<template>
  <div>
    <Navbar />
    <!-- Hero Start -->
    <section class="bg-half-170  d-table w-100" id="home">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6">
            <div class="title-heading mt-4">
              <h1 class="heading mb-3">
                Our Expertise Is Your
                <span class="text-primary">Success</span>
              </h1>
              <p class="para-desc text-muted">
                The All-in-One Partner that powers your next competitive edge
                and provides all your IT infrastructure needs.
              </p>
              <div class="mt-4">
                <router-link to="/contact" class="btn btn-primary mt-2 mr-2"
                  ><i class="mdi mdi-email"></i> Contact Us</router-link
                >
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <img src="images/illustrator/Startup_SVG.svg" alt="" />
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Hero End -->

    <!-- How It Work Start -->
    <section class="section bg-light border-bottom">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Why partner with us?</h4>
              <p class="text-muted para-desc mb-0 mx-auto">
                <span class="text-primary font-weight-bold"
                  >PromiTech Solutions</span
                >
                actively operates in the IT and data center industry, providing
                services with a differentiating touch and uniqueness
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div
              class="card explore-feature border-0 rounded text-center bg-white"
            >
              <div class="card-body">
                <div
                  class="icon rounded-circle shadow-lg d-inline-block mb-2 h2"
                >
                  <i class="uil uil-thumbs-up text-primary"></i>
                </div>
                <div class="content mt-3">
                  <router-link to="/helpcenter-faqs" class="title h5 text-dark"
                    >Best In Class</router-link
                  >
                  <p class="text-muted mt-3 mb-0">
                    We partner with the best service providers in the industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div
              class="card explore-feature border-0 rounded text-center bg-white"
            >
              <div class="card-body">
                <div
                  class="icon rounded-circle shadow-lg d-inline-block mb-2 h2"
                >
                  <i class="uil uil-smile text-success"></i>
                </div>
                <div class="content mt-3">
                  <router-link
                    to="/helpcenter-guides"
                    class="title h5 text-dark"
                    >Quality of Work</router-link
                  >
                  <p class="text-muted mt-3 mb-0">
                    We work dilligently to satisfy our clients and we stand
                    behind our work 100%
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div
              class="card explore-feature border-0 rounded text-center bg-white"
            >
              <div class="card-body">
                <div
                  class="icon rounded-circle shadow-lg d-inline-block mb-2 h2"
                >
                  <i class="uil uil-bag text-danger"></i>
                </div>
                <div class="content mt-3">
                  <router-link
                    to="/helpcenter-support-request"
                    class="title h5 text-dark"
                    >Budget Conscious</router-link
                  >
                  <p class="text-muted mt-3 mb-0">
                    We work within your budget and find ways to achieve your IT
                    goals while saving you money.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <!--end container-->
    </section>

    <section class="section bg-white bottom-border border-bottom">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-8">
            <div class="section-title text-center mb-4 pb-2">
              <h4 class="title mb-4">How do we work?</h4>
              <p class="text-muted para-desc mx-auto mb-0">
                Our approach is simple and to the point.
              </p>

              <p>
                <span class="text-primary font-weight-bold"></span>
                We understand that you are busy. That is why we developed a
                three step approach to save your valuable time and keep you
                focused on the things that matter to your business.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-md-4 mt-4 pt-2">
            <div
              class="card features work-process bg-transparent process-arrow border-0 text-center"
            >
              <div class="icons rounded h1 text-center text-danger px-3">
                <i class="uil uil-presentation-edit"></i>
              </div>

              <div class="card-body">
                <h4 class="title text-dark">Discussion</h4>
                <p class="text-muted mb-0">
                  Establish an intial understanding of your business, your
                  Information Technology needs and future aspirations.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
            <div
              class="card features work-process bg-transparent process-arrow border-0 text-center"
            >
              <div class="icons rounded h1 text-center text-primary px-3">
                <i class="uil uil-airplay"></i>
              </div>

              <div class="card-body">
                <h4 class="title text-dark">Strategy & Solutioning</h4>
                <p class="text-muted mb-0">
                  Identify the best in class solution to address your need and
                  set a strategy in motion to implement it.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
            <div
              class="card features work-process bg-transparent d-none-arrow border-0 text-center"
            >
              <div class="icons rounded h1 text-center text-success px-3">
                <i class="uil uil-image-check"></i>
              </div>

              <div class="card-body">
                <h4 class="title text-dark">Execution & Validation</h4>
                <p class="text-muted mb-0">
                  We take it from here but we keep you in the loop with regular
                  updates.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-lg-12 col-md-12 text-center">
                <div class="title-heading mt-4">
                  <div class="mt-4">
                    <router-link to="/contact" class="btn btn-primary mt-2 mr-2"
                      ><i class="mdi mdi-email"></i> Get Started</router-link
                    >
                  </div>
                </div>
              </div>
              <!--end col-->
              <!--end col-->
            </div>
          </div>
          <!--end row-->
        </div>
      </div>
      <!--end container-->

      <!--end container-->
    </section>
    <section class="bg-light">
      <div class="container ">
        <div class="row align-items-end mb-4 pb-4">
          <!--end col-->

          <div class="col-md-4 mt-4 mt-sm-0">
            <div class="text-center text-md-right"></div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
        <div class="container mt-100 mt-60">
          <div class="row justify-content-center">
            <div class="col-8">
              <div class="section-title text-center mb-4 pb-2">
                <h4 class="title mb-4">What do we offer?</h4>
              </div>
            </div>
            <!--end col-->
          </div>

          <div class="row align-items-center">
            <div class="col-lg-6 col-md-5">
              <div class="app-feature-shape-left position-relative">
                <div class="text-center text-md-left">
                  <img
                    src="images/hosting/1.png"
                    class="img-fluid"
                    style="max-width:80%"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-6 col-md-7 mt-5 mt-sm-0">
              <div class="section-title">
                <h1 class="title mb-3">
                  <br />
                  Cloud Services
                </h1>
                <p class="para-desc text-muted">
                  Our cloud capabilities include consulting, design, migration,
                  integration, managed services, and support through the
                  following solutions:
                </p>

                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Data Center Modernization
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Hybrid Cloud
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >DevOps
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >DevSecOps
                  </li>

                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Managed Cloud Services
                  </li>
                </ul>
                <div class="mt-4">
                  <router-link to="/cloud" class="mt-3 h6 text-primary"
                    >Find Out More <i class="mdi mdi-chevron-right"></i
                  ></router-link>
                </div>
              </div>
            </div>

            <!--end col-->
          </div>
          <!--end row-->
        </div>

        <div class="container  mt-60">
          <div class="row align-items-center">
            <!--end col-->

            <div class="col-lg-6 col-md-7 mt-5 mt-sm-0">
              <div class="section-title">
                <h1 class="title mb-3">
                  <br />
                  Digital Transformation
                </h1>
                <p class="para-desc text-muted">
                  Our Digital Infrastructure solutions help make existing IT
                  infrastructure more efficient, while equipping our clients for
                  a cloud-flexible, mobile-ready, highly secured, insight-driven
                  future
                </p>

                <ul class="list-unstyled text-muted">
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Data Centers
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Networking
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Collaboration
                  </li>
                  <li class="mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uim uim-check-circle"></i></span
                    >Analytics
                  </li>
                </ul>
                <div class="mt-4">
                  <router-link
                    to="/digital-transformation"
                    class="mt-3 h6 text-primary"
                    >Find Out More <i class="mdi mdi-chevron-right"></i
                  ></router-link>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-5 mb-100">
              <div class="app-feature-shape-left position-relative">
                <div class="text-center text-md-left">
                  <img
                    src="images/hosting/2.png"
                    class="img-fluid"
                    alt=""
                    style="max-width:80%"
                  />
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end row-->
      </div>
    </section>
    <!--end section-->

    <!--end section-->

    <!--end section-->
    <!-- How It Work End -->

    <!-- Testi End -->

    <!-- Pricing End -->
    <!-- FAQ n Contact Start -->

    <!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-footer">
        <svg
          viewBox="0 0 2880 250"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M720 125L2160 0H2880V250H0V125H720Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!-- FAQ n Contact End -->

    <Footer />

    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
